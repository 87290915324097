<template>
  <div class="container-fluid">
    <div class="header row">
      <div class="col-md-1 desktop-only">&nbsp;</div>
      <div class="col-md-11 desktop-only" style="float: left">
        <img
          style="float: left; padding-right: 25px; max-width: 20%"
          src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/comics-logo-copy-04.png"
        />
        <a
          style="float: right; margin-right: 150px"
          @click="onLoginClick"
          href="/login"
          class="myButton"
          >Login</a
        >
        <p style="">
          <span
            style="font-size: 30px; font-family: Nunito-Regular; color: black"
            >Pratilipi Comics</span
          ><br />
          <span
            style="
              font-family: Nunito-Light;
              font-size: 25px;
              color: gray;
              font-weight: 100;
              letter-spacing: 1px;
            "
            >where stories come alive…</span
          >
        </p>
      </div>
      <div class="col-md-12 mobile-only" style="float: left">
        <img
          style="float: left; padding-right: 5px; max-width: 20%"
          src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/comics-logo-copy-04.png"
        />
        <a style="float: right" href="/login" class="myButton">Login</a>
        <p style="">
          <span
            style="font-size: 20px; font-family: Nunito-Regular; color: black"
            >Pratilipi Comics</span
          ><br />
          <span
            style="
              font-family: Nunito-Light;
              font-size: 15px;
              color: gray;
              font-weigh: 100;
              letter-spacing: 1px;
            "
            >where stories come alive…</span
          >
        </p>
      </div>
    </div>
    <a
      href="/login"
      class="row h-100 justify-content-center align-items-center"
      style="padding-bottom: 100px"
    >
      <img
        style="max-width: 70%"
        src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/poster.png"
      />
    </a>
    <div style="padding-left: 10%; padding-right: 10%">
      <div
        class="row h-100 justify-content-center align-items-center"
        style="text-align: center"
      >
        <div class="col-md-6 desktop-only" style="font-size: 40px">
          Looking for us?<br />
          <a
            href="https://play.google.com/store/apps/details?id=com.pratilipi.comics&hl=en_IN"
            ><img
              src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/google-play-badge.png"
              height="150"
              width="350"
          /></a>
        </div>
        <div class="col-md-6">
          <div
            id="demo"
            class="carousel slide"
            data-ride="carousel"
            data-interval="3000"
          >
            <ul class="carousel-indicators">
              <li data-target="#demo" data-slide-to="0" class="active"></li>
              <li data-target="#demo" data-slide-to="1"></li>
              <li data-target="#demo" data-slide-to="2"></li>
              <li data-target="#demo" data-slide-to="3"></li>
              <li data-target="#demo" data-slide-to="4"></li>
              <li data-target="#demo" data-slide-to="5"></li>
            </ul>

            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/dc1.png"
                  class="carouse-image"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/dc2.png"
                  class="carouse-image"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/dc3.png"
                  class="carouse-image"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/dc4.png"
                  class="carouse-image"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/dc5.png"
                  class="carouse-image"
                />
              </div>
              <div class="carousel-item">
                <img
                  src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/dc6.png"
                  class="carouse-image"
                />
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#demo" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </a>
          <a class="carousel-control-next" href="#demo" data-slide="next">
            <span class="carousel-control-next-icon"></span>
          </a>
        </div>
      </div>
      <div class="row mobile-only">&nbsp;</div>
      <div class="row mobile-only">
        <div style="font-size: 25px; text-align: center">
          Looking for us?<br />
          <a
            href="https://play.google.com/store/apps/details?id=com.pratilipi.comics&hl=en_IN"
            ><img
              src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/google-play-badge.png"
              height="120"
              width="250"
          /></a>
        </div>
      </div>
    </div>
    <div class="desktop-only">
      <div class="row" style="text-align: center; width: 100%">
        <img
          style="padding-right: 20px; margin-left: 33%"
          src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/group-18.svg"
        />
        <p style="">
          &nbsp;<br />&nbsp;<br />
          <span class="horizontal-line1">&nbsp;</span> <br />
          <span style="font-size: 40px; color: black">Pratilipi Comics</span
          ><br />
        </p>
      </div>
    </div>
    <div class="row mobile-only">&nbsp;</div>
    <div class="row mobile-only" style="width: 100%">
      <div style="position: relative; margin-left: 10%">
        <img
          src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/group-18.svg"
          style="width: 35%; float: left"
        />
        <p style="">
          &nbsp;<br />&nbsp;<br /><span style="font-size: 20px; color: black"
            >&nbsp;&nbsp;Pratilipi Comics</span
          ><br />
        </p>
      </div>
    </div>
    <!--    <div class="row" style="text-align: right;margin-top: 2%">-->
    <!--        <span style="width:100%;font-size:40px;text-align: center">Pratilipi Comics</span>-->
    <!--    </div>-->
    <div class="row" style="margin-top: 2%">
      <div class="col-md-4">&nbsp</div>
      <div class="col-md-4">
        <div
          class="desktop-only"
          style="
            width: 100%;
            font-size: 25px;
            text-align: center;
            color: dimgrey;
          "
        >
          Revisit your favorite childhood stories or find your new treasured
          tales. Stories that are meant for you are one app download away.
        </div>
        <div
          class="mobile-only"
          style="
            width: 100%;
            font-size: 20px;
            text-align: center;
            color: dimgrey;
          "
        >
          Revisit your favorite childhood stories or find your new treasured
          tales. Stories that are meant for you are one app download away.
        </div>
      </div>
    </div>
    <div class="row" style="padding-bottom: 4%">&nbsp; &nbsp;</div>

    <div class="desktop-only">
      <div
        class="row"
        style="
          background-image: url('https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/group-20.svg');
          font-size: 30px;
          text-align: center;
          padding-bottom: 2%;
          padding-top: 3%;
        "
      >
        <div class="col">&nbsp;</div>
        <div class="col-md-3" style="padding: 5%; padding-bottom: 0px">
          Classic and New Comics
        </div>
        <div class="col-md-3" style="padding: 5%; padding-bottom: 0px">
          One platform for readers
          <img
            src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/group-111.svg"
            class="thought-img"
            style="
              margin-top: -160%;
              margin-left: 50%;
              height: 150%;
              width: 150%;
              position: relative;
            "
          />
        </div>
        <div class="col-md-3" style="padding: 5%; padding-bottom: 0px">
          Genres for every mood
        </div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="mobile-only">
      <img
        src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/group-111.svg"
        style="
          max-height: 50%;
          max-width: 50%;
          margin-bottom: -14%;
          margin-left: 40%;
        "
      />
      <div
        class="row"
        style="
          background-image: url('assets/group-20.svg');
          font-size: 20px;
          text-align: center;
          padding-bottom: 2%;
          padding-top: 3%;
        "
      >
        <div class="col">&nbsp;</div>
        <div class="col-md-3" style="padding: 5%; padding-bottom: 0px">
          Classic and New Comics
        </div>
        <div class="col-md-3" style="padding: 5%; padding-bottom: 0px">
          Platform for readers and creators
        </div>
        <div class="col-md-3" style="padding: 5%; padding-bottom: 0px">
          Genres for every mood
        </div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div
      class="desktop-only"
      style="text-align: center; font-size: 30px; padding-top: 3%"
    >
      <span>Subscribe and Download to read wherever you go.</span>
    </div>
    <div
      class="desktop-only"
      style="text-align: center; font-size: 30px; padding-bottom: 3%"
    >
      <a
        href="https://play.google.com/store/apps/details?id=com.pratilipi.comics&hl=en_IN"
        ><img
          src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/google-play-badge.png"
          height="150"
          width="350"
      /></a>
    </div>
    <div
      class="mobile-only"
      style="text-align: center; font-size: 20px; padding-top: 3%"
    >
      <span>Subscribe and Download to read wherever you go.</span>
    </div>
    <div
      class="mobile-only"
      style="text-align: center; font-size: 20px; padding-bottom: 3%"
    >
      <a
        href="https://play.google.com/store/apps/details?id=com.pratilipi.comics&hl=en_IN"
        ><img
          src="https://static-pratilipicomic.s3.ap-south-1.amazonaws.com/assets/google-play-badge.png"
          height="100"
          width="250"
      /></a>
    </div>
    <div class="desktop-only">
      <div
        class="row"
        style="
          text-align: center;
          font-size: 30px;
          background-color: #0a1b1c;
          color: white;
          padding-top: 2%;
        "
      >
        <div class="col-md-4">&nbsp;</div>
        <div class="col-md-4" style="padding-bottom: 2%">
          <span>Publish with Pratilipi Comics<br /></span>
          <span style="color: #f5bc19; font-size: 25px"
            >Send us your details, we will get in touch with you.</span
          >
          <form>
            <div class="form-row" style="text-align: center">
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  style="background-color: #0a1b1c; color: white"
                  placeholder="First name"
                />
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  style="background-color: #0a1b1c; color: white"
                  placeholder="Last name"
                />
              </div>
            </div>
            <button type="button" class="btn btn-warning" style="padding: 10px">
              Submit<i
                class="fas fa-location-arrow"
                style="font-size: 20px"
              ></i>
            </button>
          </form>
        </div>
        <div class="col-md-4">&nbsp;</div>
      </div>
    </div>
    <div
      class="row mobile-only"
      style="
        text-align: center;
        font-size: 20px;
        background-color: #0a1b1c;
        color: white;
        padding-top: 2%;
      "
    >
      <div class="col-md-4">&nbsp;</div>
      <div class="col-md-12" style="padding-bottom: 2%">
        <span>Got a comic to add?<br /></span>
        <span style="color: #f5bc19; font-size: 20px"
          >Send us your details, we will get in touch with you.</span
        >
        <form>
          <div class="form-row" style="text-align: center">
            <input
              type="text"
              class="form-control"
              style="background-color: #0a1b1c; color: white"
              placeholder="First name"
            />
          </div>
          &nbsp;
          <div class="form-row" style="text-align: center">
            <input
              type="text"
              class="form-control"
              style="background-color: #0a1b1c; color: white"
              placeholder="Last name"
            />
          </div>
          <br />
          <button type="button" class="btn btn-warning" style="padding: 10px">
            Submit<i class="fas fa-location-arrow" style="font-size: 20px"></i>
          </button>
        </form>
      </div>
      <div class="col-md-4">&nbsp;</div>
    </div>
    <div class="desktop-only">
      <div class="row" style="margin-top: 5%">
        <span class="pratilipi-text">Pratilipi</span>
      </div>
      <div class="row" style="margin-top: 2%">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <div class="th-5th-Floor-Son">
            4th & 5th Floor, Sona Tower, Krishna Nagar Industrial Area, Hosur
            Main Road,Bengaluru, Karnataka 560029 CIN: U72200KA2015PTC079230 -
            Email: contact@pratilipi.com - Phone: 080 41710149
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row" style="margin-top: 2%">
        <span style="width: 100%; text-align: center" class="footer-text">
          <router-link :to="{ name: 'PrivacyPolicy' }">
            <a>Privacy Policy</a>
          </router-link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; |
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <router-link :to="{ name: 'TermOfService' }">
            <a>Terms of Service</a>
          </router-link>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; |
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; © 2017 Nasadiya Tech. Pvt. Ltd.
        </span>
      </div>
    </div>
    <div
      class="row mobile-only"
      style="margin-top: 5%; text-align: left; font-size: 15px"
    >
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <span class="pratilipi-text" style="text-align: left; font-size: 17px"
          >Pratilipi</span
        >
      </div>
    </div>
    <div class="row mobile-only" style="margin-top: 5%">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="th-5th-Floor-Son" style="text-align: left; font-size: 17px">
          4th & 5th Floor, Sona Tower, Krishna Nagar Industrial Area, Hosur Main
          Road,Bengaluru, Karnataka 560029 <br /><br />
          CIN: U72200KA2015PTC079230 <br />
          Email: contact@pratilipi.com <br /><br />
          Phone: 080 41710149
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
    <div class="row mobile-only" style="margin-top: 2%; text-align: center">
      <span
        class="footer-text"
        style="width: 100%; text-align: center; font-size: 14px"
      >
        Privacy Policy &nbsp;| &nbsp;Terms of Service&nbsp; | &nbsp;&nbsp;© 2017
        Nasadiya Tech. Pvt. Ltd.
      </span>
    </div>
  </div>
</template>

<script>
  import mixins from '@/mixins';
    
  export default {
    mixins: [
      mixins
    ],
    methods: {
      onLoginClick() {
        this.triggerAnanlyticsEvent("CLICK_LOGIN");
      }
    }
  }
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css";
.header {
  padding: 5%;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: black;
}

.active {
  color: white;
}

.btn-warning {
  font-family: Raleway-SemiBold;
  font-size: 25px;
  color: black;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #f5bc19;
  border-radius: 40px;
  background-color: #f5bc19;
  transition: all 0.3s ease 0s;
  padding-top: 2%;
  padding-bottom: 2%;
}

.btn-warning:hover {
  color: #fff;
  background: #f5bc19;
  border: 2px solid rgba(240, 173, 78, 0.75);
}

.pratilipi-text {
  width: 100%;
  text-align: center;
  font-family: Nunito;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0a1b1c;
}

.th-5th-Floor-Son {
  width: 100%;
  text-align: center;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0a1b1c;
}

.footer-text {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0a1b1c;
}

.myButton {
  background-color: #000000;
  border-radius: 6px;
  border: 1px solid #000000;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  font-weight: bold;
  padding: 16px 31px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #000000;
}

.horizontal-line {
  border: solid 3px #f5bc19;
}

.carouse-image {
  width: 500px;
  height: 500px;
}

@media only screen and (max-width: 450px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }

  .tablet-only {
    display: none;
  }

  .carouse-image {
    width: 280px;
    height: 300px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1050px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }

  .tablet-only {
    display: block;
  }
}

@media only screen and (min-width: 1050px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }

  .tablet-only {
    display: block;
  }
}


</style>
